<template>
  <div>
    <upload-sheets
      :url_path="`label/excel/${masterInfo.lbl_cat}`"
      :showModal="showModalUpload"
      @cancel="
        () => {
          showModalUpload = false;
        }
      "
        @done="getData"
    />
    <div>
      <!-- Table Top -->

      <!-- <b-card no-body> -->
      <b-row cols="12" class="p-1">
        <b-col cols="6">
          <b-form-group label="Search">
            <b-form-input
              v-model="search"
              @input="searchTyped"
              class="w-full"
              :placeholder="'Search ' + masterInfo.lbl_cat"
            />
          </b-form-group>
        </b-col>

        <b-col cols="6">
          <b-form-group label="Sort order">
            <v-select
              v-model="selectedSortOrder"
              :reduce="(label) => label.value"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="sortOrders"
              :clearable="false"
              @option:selected="limitChanged"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <!-- </b-card> -->

      <b-row class="mb-1">
        <b-col cols="8" class="d-flex">
          <b-button-group size="sm">
            <b-button
              size="sm"
              v-if="$can('create', 'master')"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              class="w-100"
              variant="gradient-primary"
              @click="
                () => {
                  openForm = true;
                  selectedItem = {};
                }
              "
            >
              <!-- <feather-icon size="14" icon="PlusIcon" /> -->
              <span class="text-nowrap">
                Add
                {{ catName }}</span
              >
            </b-button>
            <b-button
              v-if="$can('create', 'master') && showGroupButton"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="gradient-info"
              size="sm"
              @click="
                () => {
                  openGroupForm = true;
                  selectedItem = {};
                }
              "
            >
              <!-- <feather-icon size="14" icon="PlusIcon" /> -->
              <span class="text-nowrap">
                Add
                {{ catName }}
                Group</span
              >
            </b-button>

            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-success"
              @click="downloadMasterCSV"
              class="w-100"
              size="sm"
            >
              <span class="text-nowrap"> Export {{ catName }} </span>
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-success"
              class="w-100"
              size="sm"
              @click="
                () => {
                  showModalUpload = true;
                }
              "
            >
              <span class="text-nowrap"> Import {{ catName }} </span>
            </b-button>
          </b-button-group>
        </b-col>
        <b-col
          class="d-flex align-items-center justify-content-end mt-1"
          cols="4"
        >
          <b-form-checkbox
            v-model="deleted"
            name="check-button"
            inline
            @input="showDeleteToggled"
          >
            <b><u>show deleted entries</u></b>

            <!-- {{ masterInfo.lbl_cat.replace(/_/g, " ") }} entries -->
          </b-form-checkbox>
        </b-col>
      </b-row>
    </div>

    <b-card no-body :title="masterInfo.title">
      <b-table
        small
        hover
        bordered
        class="position-relative"
        :items="tableData"
        responsive
        :fields="tableColumns"
        primary-key="lbl_id"
        show-empty
        empty-text="No matching records found"
      >
        <template #cell(sr)="data">
          <span>
            {{
              data.index + 1 + (isNaN(limit) ? 0 : (currentPage - 1) * limit)
            }}
          </span>
        </template>

        <template #cell(order)="data">
          {{ data.item.order == "999999999" ? "-" : data.item.order }}
        </template>

        <template #cell(lbl_value2)="data">
          {{ data.item.lbl_value2 }}
          {{
            data.item.lbl_cat == "tax" &&
            data.item.lbl_value1 &&
            data.item.lbl_value1.toLowerCase() == "Percentage/Fixed"
              ? "%"
              : ""
          }}
        </template>

        <template #cell(lbl_value3)="data">
          {{
            data.item.lbl_cat == "tax" &&
            data.item.revenue_upload_validation &&
            data.item.revenue_upload_validation != "0"
              ? data.item.revenue_upload_validation
              : ""
          }}
        </template>

        <!-- Status column -->
        <template #cell(status)="data">
          <b-badge
            pill
            :variant="
              data.item.deleted == 1
                ? 'light-danger'
                : data.item.status == 1
                ? 'light-success'
                : 'light-warning'
            "
          >
            {{
              data.item.deleted == 1
                ? "Deleted"
                : data.item.status == 1
                ? "Active"
                : "Inactive"
            }}
          </b-badge>
        </template>

        <!-- Status childern -->
        <template #cell(childern)="data">
          <b-button
            v-if="!data.item.lbl_is_group"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            v-b-modal.modal-center
            variant="outline-primary"
            size="sm"
            @click="
              () => {
                selectedItem = data.item;
              }
            "
          >
            View
          </b-button>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <span>
              <feather-icon
                style="margin-right: 10px; cursor: pointer"
                size="18"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-tooltip.hover.right.v-primary
                variant="outline-primary"
                title="Copy"
                @click="
                  () => {
                    selectedItem = { ...data.item, is_copy: true };
                    if (data.item.lbl_is_group) {
                      openGroupForm = true;
                    } else {
                      openForm = true;
                      defaultSortOrder =
                        data.index + 1 + (currentPage - 1) * limit;
                    }
                  }
                "
                icon="CopyIcon"
              />
            </span>
            <span v-if="data.item.deleted == true">
              <feather-icon
                v-if="$can('delete', 'master')"
                style="cursor: pointer"
                class="ml-1"
                size="18"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-tooltip.hover.right.v-primary
                variant="outline-primary"
                title="Restore"
                @click="() => setDeleteItem(data.item.lbl_id, 0)"
                icon="RotateCcwIcon"
              />
            </span>
            <span v-else>
              <feather-icon
                v-if="!data.item.lbl_is_group"
                size="18"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-tooltip.hover.right.v-primary
                variant="outline-primary"
                style="cursor: pointer"
                title="Edit"
                @click="
                  () => {
                    openForm = true;
                    selectedItem = data.item;
                    defaultSortOrder =
                      data.index + 1 + (currentPage - 1) * limit;
                  }
                "
                icon="EditIcon"
              />
              <feather-icon
                v-if="data.item.lbl_is_group"
                size="18"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                style="cursor: pointer"
                v-b-tooltip.hover.right.v-primary
                variant="outline-primary"
                title="Edit"
                @click="
                  () => {
                    openGroupForm = true;
                    selectedItem = data.item;
                  }
                "
                icon="EditIcon"
              />
              <feather-icon
                v-if="$can('delete', 'master')"
                class="ml-1"
                size="18"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                style="cursor: pointer"
                v-b-tooltip.hover.right.v-primary
                variant="outline-primary"
                title="Delete"
                @click="() => showDeleteConfirm(data.item.lbl_id)"
                icon="Trash2Icon"
              />
            </span>
          </div>
        </template>
      </b-table>

      <template #footer>
        <div>
          <b-row class="d-flex align-items-end">
            <b-col class="d-flex justify-content-start" cols="3">
              <v-select
                @option:selected="limitChanged"
                v-model="limit"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
              />
              <label class="mt-1">entries per page</label>
            </b-col>
            <b-col class="d-flex justify-content-end" cols="9">
              <b-pagination
                @change="onPageChange"
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="limit"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
          <!-- <b-row> </b-row> -->
        </div>
      </template>
    </b-card>

    <!-- Label Form -->
    <b-sidebar
      id="master-form"
      v-model="openForm"
      bg-variant="white"
      sidebar-class="sidebar-lg"
      shadow
      backdrop
      no-header
      right
    >
      <div>
        <MasterForm
          @refreshList="getData"
          :masterInfo="masterInfo"
          :selectedItem="selectedItem"
          :closeSidebar="hide"
          :defaultSortOrder="defaultSortOrder"
        />
      </div>
    </b-sidebar>

    <!-- Label Group Form -->
    <b-sidebar
      id="master-form"
      v-model="openGroupForm"
      bg-variant="white"
      sidebar-class="sidebar-lg"
      shadow
      backdrop
      no-header
      right
    >
      <div>
        <MasterGroupForm
          @refreshList="getData"
          :masterInfo="masterInfo"
          :masterList="tableData"
          :selectedItem="selectedItem"
          :closeSidebar="hide"
          :defaultSortOrder="defaultSortOrder"
        />
      </div>
    </b-sidebar>

    <b-modal size="lg" id="modal-center" centered hide-footer>
      <SubMasterTable :masterInfo="masterInfo" :parentInfo="selectedItem" />
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BCardHeader,
  BCardBody,
  BSidebar,
  VBPopover,
  VBTooltip,
  BFormCheckbox,
  BButtonGroup,
  BFormGroup,
} from "bootstrap-vue";
import vSelect from "vue-select";
import MasterForm from "./MasterForm.vue";
import MasterGroupForm from "./MasterGroupForm.vue";
import SubMasterTable from "./SubMasterTable.vue";
import Ripple from "vue-ripple-directive";
import ToastificationContentVue from "@core/components/toastification/ToastificationContent.vue";
import { capitalize } from "lodash";
import { group_labels } from "@/utils/constants";
import { getAllData, deleteLabel } from "@/apiServices/MastersServices";
import { TokenService } from "@/apiServices/storageService";
import UploadSheets from "@/views/components/UploadSheets.vue";
import { downloadFromURL } from "@/utils/helpers";

export default {
  props: {
    masterInfo: Object,
  },
  directives: {
    "b-popover": VBPopover,
    "b-tooltip": VBTooltip,
    Ripple,
  },
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BButtonGroup,
    BDropdownItem,
    BPagination,
    BCardHeader,
    BCardBody,
    vSelect,
    BSidebar,
    MasterForm,
    SubMasterTable,
    VBTooltip,
    ToastificationContentVue,
    BFormCheckbox,
    MasterGroupForm,
    UploadSheets,
    BFormGroup,
  },

  data() {
    return {
      showModalUpload: false,
      selectedItem: {},
      deleted: false,

      currentPage: 1,
      totalPages: 0,
      limit: 10,
      totalRows: 0,
      // perPageOptions: [10, 25, 50, 100, "All"],
      perPageOptions: [10, 25, 50, 100, 1000],

      search: "",
      tableData: [],
      defaultSortOrder: null,

      tableColumns: ["order", "lbl_name", "lbl_value1", "lbl_value2"],

      sortOrders: [
        {
          label: "By Ascending",
          value: "asc",
        },
        {
          label: "By Descending",
          value: "desc",
        },
      ],

      selectedSortOrder: "asc",

      openForm: false,
      openGroupForm: false,
    };
  },

  computed: {
    catName() {
      return capitalize(this.masterInfo.lbl_cat.replace(/_/g, " "));
    },
    showGroupButton() {
      return group_labels.includes(this.masterInfo.lbl_cat);
    },
  },

  watch: {
    masterInfo() {
      this.currentPage = 1;
      this.limit = 10;
      this.deleted = false;
      this.changeColumns();
      this.getData();
    },
    search() {
      this.getData();
    },
  },

  methods: {
    downloadMasterCSV() {
      try {
        const token = TokenService.getToken();

        if (!token) {
          alert("Error in getting token");
        }

        let urlStr = `${process.env.VUE_APP_BASEURL}/label/excel/${this.masterInfo.lbl_cat}?authorization=${token}`;

        downloadFromURL(urlStr, this.masterInfo.lbl_cat, "csv");
      } catch (error) {
        console.error(`Error in downloadMasterCSV `, error);
      }
    },

    showDeleteToggled() {
      this.currentPage = 1;
      this.$nextTick(() => {
        this.getData();
      });
    },

    async setDeleteItem(id, deleted) {
      const response = await deleteLabel({
        lbl_id: id,
        deleted: deleted ? 1 : 0,
      });
      if (response.data.status) {
        this.getData();
        return this.$toast({
          component: ToastificationContentVue,
          props: {
            title: response.data.message || "Deleted Successfully",
            icon: "EditIcon",
            variant: "success",
          },
        });
      }
      return this.$toast({
        component: ToastificationContentVue,
        props: {
          title: response.data.message || "Delete Failed",
          icon: "EditIcon",
          variant: "failure",
        },
      });
    },

    showDeleteConfirm(selected_id) {
      this.$bvModal
        .msgBoxConfirm("Are you sure, you want to delete?", {
          title: "Confirmation",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true,
        })
        .then((value) => {
          if (value === true) this.setDeleteItem(selected_id, 1);
        })
        .catch((err) => {
          // An error occurred
        });
    },

    searchTyped() {
      this.$nextTick(() => {
        this.getData();
      });
    },

    limitChanged(val) {
      this.currentPage = 1;
      this.getData();
      localStorage.setItem("sortOrder", this.selectedSortOrder);
    },

    onPageChange() {
      this.$nextTick(() => {
        this.getData();
      });
    },

    async getData() {
      let response = await getAllData({
        master: this.masterInfo.lbl_cat,
        search: this.search,
        deleted: this.deleted ? 1 : 0,
        limit: this.limit == "All" ? undefined : this.limit,
        page: this.currentPage,
        selectedSortOrder: this.selectedSortOrder,
      });

      if (response.status == 200) {
        this.tableData = response.data.data.rows;
        this.totalRows = !isNaN(this.limit) ? response.data.data.count : null;
        if (isNaN(this.limit)) this.totalPages = 1;
      }
    },

    hide() {
      this.openForm = false;
      this.openGroupForm = false;
      this.selectedItem = {};
    },

    changeColumns() {
      let column = [];
      column.push("sr");

      Object.keys(this.masterInfo).map((key) => {
        if (key == "lbl_name") {
          column.push({
            label: this.masterInfo.lbl_name,
            key: key,
          });
        }
        if (key == "lbl_value1") {
          column.push({
            label: this.masterInfo.lbl_value1,
            key: key,
          });
        }
        if (key == "lbl_value2") {
          column.push({
            label: this.masterInfo.lbl_value2,
            key: key,
          });
        }
        if (key == "lbl_value3") {
          column.push({
            label: this.masterInfo.lbl_value3,
            key: key,
          });
        }
        if (key == "order") {
          column.push({
            label: "Order",
            key: key,
          });
        }
        if (key == "status") {
          column.push({
            label: this.masterInfo.status,
            key: key,
          });
        }
        if (key == "childern") {
          column.push({
            label: this.masterInfo.childern.title,
            key: key,
          });
        }
      });

      if (this.$can("update", "master")) {
        column.push({ key: "actions" });
      }
      this.tableColumns = column;
    },
  },

  beforeMount() {
    const sortOrderFound = localStorage.getItem("sortOrder");
    if (sortOrderFound) {
      this.selectedSortOrder = sortOrderFound;
    }

    if (this.masterInfo) {
      this.changeColumns();
      this.getData();
    }
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.per-page-selector {
  width: 90px;
}
</style>
